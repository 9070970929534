import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[scrollReaction]'
})
export class ScrollReactionDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }


  @HostListener('mouseup', ['$event'])
  onWindowScroll(event: any) {
    let sibling=this.el.nativeElement.parentNode;
    (<HTMLElement>sibling).scrollTo({top:0,behavior:'smooth',left:0})
  }
}
