import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[scroll]',
})
export class ScrollDirective {
  @Output() scrolledDown: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('scroll', ['$event'])
  onWindowScroll(event: any) {
    if (this.el.nativeElement.scrollTop > 250) {
      this.scrolledDown.emit(true);
    } else {
      this.scrolledDown.emit(false);
    }
  }
}
