<mat-card class="map-Wrap">
    <loader *ngIf="!positions">
    </loader>
    <ng-container *ngIf="positions && positions !='error'">
        <google-map [height]="height" [width]="width" [center]="positions[0]" [zoom]="zoom">
            <map-marker *ngFor="let position of positions;" [position]="position"></map-marker>
        </google-map>
    </ng-container>
    <ng-container *ngIf="positions && positions =='error'">
        <error-state></error-state>
    </ng-container>
</mat-card>