import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isInitialized = false;

  constructor(
    private keycloak:KeycloakService,
    private router:Router
    ) { }

    initializeKeycloak():Promise<boolean>{
      if(this.isInitialized){
        return Promise.resolve(true)
      }
      this.isInitialized=true;
      return this.keycloak.init({
        config:{
          url:environment.keycloakUrl,
          clientId:"platform",
          realm:"horizon-view"
        },
        initOptions:{
          onLoad:'check-sso'
        }
      })
    }

    async getUserData():Promise<any>{
      const loggedIn=await this.keycloak.isLoggedIn()
      if(loggedIn){
        const user = await this.keycloak.loadUserProfile()
        return user;
      }
      return null
    }
}
