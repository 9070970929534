<mat-drawer-container class="cargo-container" autosize (backdropClick)="changeState()" >
    <mat-drawer #drawer class="cargo-sidenav" [opened]="panelSettings.opened" [mode]="panelSettings.mode">
      <mat-nav-list style="margin-top: 30px">
        <mat-list-item [routerLink]="['demo/home']">
          <a
            matLine
            [routerLinkActive]="['is-active']"
            [routerLinkActiveOptions]="{ exact: true }"
            color="primary"
            fxLayout="row"
            fxLayoutAlign=" center"
          >
            <span>
              <mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
            </span>
            <span *ngIf="showFiller" style="margin-left: 0.5rem">Home</span>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </mat-drawer>
    <mat-toolbar style="background-color: white">
      <div fxLayout="row" fxLayoutAlign=" center">
        <button
          mat-icon-button
          (click)="graphState()"
          aria-label="Example icon-button with menu icon"
        >
          <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
      </div>
  
      <span class="example-spacer"></span>
      <div fxLayout="row" fxLayoutAlign=" center">
        <img
        src="../../../../assets/icons/toolbar-logo-1.jpeg"
        alt="logo"
        class="logoResize"
      />
        <button
          mat-icon-button
          aria-label="Example icon-button with menu icon"
          matTooltip="Logout"
          (click)="logout()"
        >
          <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        </button>
      </div>
    </mat-toolbar>
    <div class="cargo-sidenav-content" scroll (scrolledDown)="scrollEvent($event)">
      <button *ngIf="scrolled" mat-fab color="primary" class="buttonUp" scrollReaction>
        <mat-icon [svgIcon]="'heroicons_outline:chevron-up'"></mat-icon>
      </button>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>