import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './core/layout/layout.module';
import { IconsModule } from './core/icons/icons.module';
import { AuthService } from './auth/auth.service';
import { KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { DashGoogleMapModule } from './core/map/google-map/dash-google-map.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    IconsModule,
    GoogleMapsModule,
    DashGoogleMapModule
  ],
  providers: [
    AuthService,
    KeycloakService,
    {
      provide:APP_INITIALIZER,
      useFactory:(authService:AuthService)=>()=> authService.initializeKeycloak(),
      multi:true,
      deps:[AuthService]
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:KeycloakBearerInterceptor,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
