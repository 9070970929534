import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule()
export class IconsModule {
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry
  ) {
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/heroicons-outline.svg'
      )
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/heroicons-solid.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'grafana_logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/grafana-icon.svg'
      )
    );
  }
}
