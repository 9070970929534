import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'HV-demo';
  loaded:boolean=false;
  ngOnInit(): void {
    this.loadScriptByUrl(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}`,
      function (scriptref: any, id: any) {
        // do your work after load
        
      }
    );
  }

  loadScriptByUrl(url: string, afterload: any) {
    let dynamicScript = document.createElement('script');
    dynamicScript.type = 'text/javascript';
    dynamicScript.async = true;
    dynamicScript.src = url;
    dynamicScript.id = 'dynamic_';
    document.body.appendChild(dynamicScript);

    dynamicScript.addEventListener('load', () => {
      afterload(dynamicScript, dynamicScript.id);
      this.loaded=true;
    });
  }
}
