import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';

interface ISidepanelConfig {
  opened: boolean;
  mode: 'side' | 'over';
}

@Component({
  selector: 'main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  showFiller:boolean = false;
  scrolled: boolean = false;
  screenState: Subscription | undefined;
  panelSettings: ISidepanelConfig = {
    mode: 'side',
    opened: true,
  };
  constructor(
    protected readonly keyclaok: KeycloakService,
    private screenBreakpoints: BreakpointObserver
  ) {}

  ngOnDestroy(): void {
    if (this.screenState) {
      this.screenState.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.setListeners();
  }

  setListeners() {
    this.screenState = this.screenBreakpoints
      .observe(['(max-width:960px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.panelSettings = {
            opened: false,
            mode: 'over',
          };

          this.showFiller = true;
        } else {
          this.scrolled = false;
          this.panelSettings = {
            mode: 'side',
            opened: true,
          };
          this.showFiller = false;
        }
      });
  }

  graphState() {
    if (this.panelSettings.mode == 'side') {
      this.showFiller = !this.showFiller;
    } else {
      this.panelSettings.opened = true;
    }
  }

  changeState() {
    this.panelSettings.opened = false;
  }

  scrollEvent(event: boolean) {
    this.scrolled = event;
  }
  logout() {
    this.keyclaok.logout();
  }
}
