import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.scss']
})
export class ErrorStateComponent implements OnInit {

  @Input() errorDescription:string='Error fetching data...'

  constructor() { }

  ngOnInit(): void {
  }

}
