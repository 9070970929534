import { Component, Input, OnInit } from '@angular/core';
import { IGoogleMapData } from 'src/app/modules/home/components/component-types';

@Component({
  selector: 'dash-google-map',
  templateUrl: './dash-google-map.component.html',
  styleUrls: ['./dash-google-map.component.scss']
})
export class DashGoogleMapComponent implements OnInit {

  @Input() height:string='290px'
  @Input() width:string='100%'
  @Input() zoom:number=16;
  @Input() positions:IGoogleMapData[]|undefined|'error';
  

  constructor() { }

  ngOnInit(): void {
  }

}
