import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './core/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'demo',
        canActivate: [AuthGuard],
        children:[
          {
            path:'home',
            loadChildren: ()=> import('../app/modules/home/home.module').then((m)=>m.HomeModule)
          },
          {
            path:'',
            redirectTo:'home',
            pathMatch:'full'
          }
        ]
      },
      {
        path:'**',
        redirectTo:'/demo/home'
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
